declare global {
  interface Window {
    _cio: any;
  }
}

type CIOIdentifyEvent =
  | {
      id: string;
      email?: string;
    }
  | {
      id?: string;
      email: string;
    };

if (process.env.NEXT_PUBLIC_ANALYTICS_DISABLED === "true") {
  Object.assign(globalThis, {
    _cio: {
      identify: () => {},
      page: () => {},
      track: () => {},
    },
  });
}

export function identify({ id, email }: CIOIdentifyEvent) {
  window._cio.identify({
    id,
    email,
    created_at: Date.now() / 1000,
  });
}

/**
 * Customer IO tag will fire once automatically on first pageview.
 * Track this flag to avoid doubling up on the initial pageview.
 */
let hasFiredBefore = false;

export function pageview(url: string, params: Record<string, any> = {}) {
  if (!hasFiredBefore) {
    hasFiredBefore = true;
    return;
  }

  // eslint-disable-next-line no-param-reassign
  params.pageType ??= url.split("/")[1];

  window._cio.page(window.location.href, params);
}

export function event(action: string, params: Record<string, any>) {
  window._cio.track(action, params);
}
